import { inject, Injectable } from '@angular/core';
import {
  ConnectionStateEnum,
  EventsHub,
  GroupEventModel,
  IEventsHubCallbacks,
  MessageEventModel,
  GroupMessageCountsChangedEvent,
  NotificationCountsChangedEvent,
  UserConnectionStatusChangedEvent,
  UserMessageModel,
  WorkoutEvent,
  UndeliveredMessageCreatedEvent,
  UserConnectionReestablishedEvent,
  UserRemovedFromGroupEvent,
} from '@goatsports/shared/util';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { distinct, distinctUntilKeyChanged, startWith, Subject } from 'rxjs';
import { NotificationCreatedEvent } from '@goatsports/core/data-access';
import { AuthenticationService } from './authentication.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class ApiEventsService implements IEventsHubCallbacks {
  private readonly authenticationService = inject(AuthenticationService);

  private coreEventsHub!: EventsHub;
  private coreHubConnection: HubConnection | undefined;
  connectionState = ConnectionStateEnum.Disconnected;
  connectionStatusChanged = new Subject<ConnectionStateEnum>();

  private _userConnectionStatusChanged =
    new Subject<UserConnectionStatusChangedEvent>();
  userConnectionStatusChanged$ =
    this._userConnectionStatusChanged.asObservable();

  private _messageCreated = new Subject<UserMessageModel>();
  messageCreated$ = this._messageCreated.asObservable();

  private _messageDeleted = new Subject<MessageEventModel>();
  messageDeleted$ = this._messageDeleted.asObservable();

  private _userJoinedGroup = new Subject<GroupEventModel>();
  userJoinedGroup$ = this._userJoinedGroup.asObservable();

  private _userLeftGroup = new Subject<GroupEventModel>();
  userLeftGroup$ = this._userLeftGroup.asObservable();

  private _userRemovedFromGroup = new Subject<UserRemovedFromGroupEvent>();
  userRemovedFromGroup$ = this._userRemovedFromGroup.asObservable();

  private _messageRead = new Subject<MessageEventModel>();
  messageRead$ = this._messageRead.asObservable();

  private _unreadMessagesCount = new Subject<GroupMessageCountsChangedEvent>();
  unreadMessagesCount$ = this._unreadMessagesCount.asObservable();

  private _unreadNotificationsCount =
    new Subject<NotificationCountsChangedEvent>();
  unreadNotificationsCount$ = this._unreadNotificationsCount.asObservable();

  private _workoutSubmitted = new Subject<WorkoutEvent>();
  workoutSubmitted$ = this._workoutSubmitted.asObservable().pipe(
    distinct((x) => x.notificationUid),
    startWith({} as WorkoutEvent),
  );

  private _notificationCreated = new Subject<NotificationCreatedEvent>();
  notificationCreated$ = this._notificationCreated
    .asObservable()
    .pipe(
      distinctUntilKeyChanged('uid'),
      startWith({} as NotificationCreatedEvent),
    );

  constructor() {
    this.authenticationService
      .isTokenReceived()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.initialize();
      });
    this.initialize();
  }

  userRemovedFromGroup(model: UserRemovedFromGroupEvent): void {
    this._userRemovedFromGroup.next(model);
  }

  userConnectionReestablished(model: UserConnectionReestablishedEvent) {
    return;
  }

  undeliveredMessageCreated(model: UndeliveredMessageCreatedEvent) {
    return;
  }

  messageCreated(model: UserMessageModel): void {
    this._messageCreated.next(model);
  }

  notificationCountsChanged(model: NotificationCountsChangedEvent): void {
    this._unreadNotificationsCount.next(model);
  }

  groupMessageCountsChanged(model: GroupMessageCountsChangedEvent) {
    this._unreadMessagesCount.next(model);
  }

  messageRead(model: MessageEventModel) {
    this._messageRead.next(model);
  }

  workoutSubmitted(model: WorkoutEvent) {
    this._workoutSubmitted.next(model);
  }

  notificationCreated(model: NotificationCreatedEvent) {
    this._notificationCreated.next(model);
  }

  userJoinedGroup(model: GroupEventModel) {
    this._userJoinedGroup.next(model);
  }

  userLeftGroup(model: GroupEventModel) {
    this._userLeftGroup.next(model);
  }

  messageDeleted(model: MessageEventModel) {
    this._messageDeleted.next(model);
  }

  userConnectionStatusChanged(model: UserConnectionStatusChangedEvent) {
    this._userConnectionStatusChanged.next(model);
  }

  private initialize() {
    if (!this.authenticationService.isUserLoggedIn()) return;

    if (this.coreHubConnection) return;

    this.coreHubConnection = new HubConnectionBuilder()
      .configureLogging(environment.production ? LogLevel.None : LogLevel.Debug)
      .withUrl(environment.coreApiUrl + '/events', {
        accessTokenFactory: () => this.authenticationService.getAccessToken(),
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          const retries = retryContext.previousRetryCount + 1;
          if (retries <= 5) {
            // Limit the number of retries
            return Math.pow(2, retries) * 1000; // Exponential backoff
          } else {
            return null; // Stop retrying after 5 attempts
          }
        },
      })
      .build();

    this.coreEventsHub = new EventsHub(this.coreHubConnection);
    this.coreEventsHub.registerCallbacks(this);

    this.start();
  }

  async start() {
    if (this.coreHubConnection?.state === HubConnectionState.Disconnected) {
      await this.coreHubConnection.start();
    }
  }

  async stop() {
    if (this.coreHubConnection?.state === HubConnectionState.Connected) {
      await this.coreHubConnection.stop();
    }
  }

  setConnectionStatus(state: ConnectionStateEnum): void {
    this.connectionState = state;
    this.connectionStatusChanged.next(state);
  }
}
